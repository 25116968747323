@use "sass:map";

/* stylelint-disable */

@mixin fluid($property, $value, $valuetablet, $valuemob) {
    // Base (mobile) property
    #{$property}: #{$valuemob}px;

    // Tablet breakpoint
    @media (width >= 768px) {
        #{$property}: #{$valuetablet}px;
    }

    // Desktop breakpoint
    @media (width >= 1024px) {
        #{$property}: calc(#{$value} / 19.19 * 1vw);
    }

    // Large desktop
    @media (width > 1920px) {
        #{$property}: #{$value}px;
    }
}
