@import "./fluid";

.wp-stories {
    display: flex;
    overflow: scroll;
    width: 100%;

    &::-webkit-scrollbar { display: none; }

    @media(width >= 1024px) {
        overflow: hidden;
    }

    @include fluid(gap, 19, 19, 19);

    &__wrapper {
        display: flex;
        align-items: center;
        position: relative;
    }

    &__prev,
    &__next {
        @include fluid(width, 30, 30, 30);
        @include fluid(height, 30, 30, 30);
        @include fluid(top, 40, 40, 40);

        padding: 0 !important;
        opacity: 0;
        visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        position: absolute;
        z-index: 3;
        transition: opacity 0.3s, visibility 0.3s;

        svg {
            @include fluid(width, 23, 23, 23);
            @include fluid(height, 13, 13, 13);
        }

        &::before {
            content:"";
            display: block;
            position: absolute;
            z-index: -1;

            @include fluid(inset, -30, -20, -15);
        }

        &--active {
            opacity: 1;
            visibility: visible;
        }
    }

    &__prev {
        @include fluid(left, 10, 0, 0);

        transform: translateX(-50%) scaleX(-1) translateY(-50%);
    }

    &__next {
        @include fluid(right, 10, 0, 0);

        transform: translateX(50%) translateY(-50%);
    }

    &__categories {
        display: flex;
        @include fluid(gap, 21, 21, 21);
        @include fluid(margin-bottom, 32, 32, 32);
    }

    &__category {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #AAAFB6;
        padding: 0;
        background-color: transparent;
        border:0;

        @include fluid(gap, 6, 6, 6);
        @include fluid(width, 80, 80, 80);
        @include fluid(font-size, 12, 12, 12);

        &-image {
            @include fluid(width, 72, 72, 72);
            @include fluid(height, 72, 72, 72);
            border-radius: 100%;

            &-wrapper {
                position: relative;
                display: flex;
                z-index: 1;
                align-items: center;
                justify-content: center;
                @include fluid(width, 80, 80, 80);
                @include fluid(height, 80, 80, 80);
            }

            &-border {
                position: absolute;
                inset: 0;
                z-index: -1;
                @include fluid(width, 80, 80, 80);
                @include fluid(height, 80, 80, 80);
            }
        }

        &.viewed {
            .wp-stories__category-image-border {
                circle {
                    stroke: #AAAFB6;
                }
            }
        }
    }
}

.wp-stories__modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100vh;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

.wp-stories__item-modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

.wp-stories__modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: hidden;
    height: 100%;
    overflow-x: hidden;
    width: 100%;
}

.wp-stories__item-modal-content {
    position: relative;
    margin: 5% auto;
    width: 100%;
}

.wp-stories__modal-close, .wp-stories__item-modal-close {
    position: absolute;
    cursor: pointer;
    z-index: 222;

    @include fluid(top, 26, 26, 0);
    @include fluid(right, 40, 40, 0);

    svg {
        @include fluid(width, 48, 48, 24);
        @include fluid(height, 48, 48, 24);
    }
}

.wp-stories__modal-slider .slick-slide, .wp-stories__item-slider .slick-slide {
    outline: none;
    margin: 0 20px;
}

.wp-story-item {
    position: relative;
    transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out;

    @media(width < 768px) {
        max-width: 95%;
    }

    &__inner {
        @include fluid(border-radius, 10, 10, 10);

        overflow: hidden;
        position: relative;
        display: none;
        height: 100%;
    }

    &--active {
        .wp-story-item__inner {
            display: block;
        }

        .wp-story-item__preview {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 2;
        color: #fff;
        justify-content: space-between;
        left: 0;
        right: 0;

        @include fluid(top, 50, 50, 50);
        @include fluid(padding-left, 15, 15, 15);
        @include fluid(padding-right, 15, 15, 15);

        &-left {
            display: flex;
            align-items: center;

            @include fluid(gap, 11, 11, 11);

            img {
                @include fluid(width, 44, 44, 44);
                @include fluid(height, 44, 44, 44);

                object-fit: cover;
                object-position: center;
                border-radius: 100%;
            }
        }

        &-title {
            font-weight: 500;

            @include fluid(font-size, 16, 16, 16);
        }

        &-date {
            @include fluid(font-size, 16, 16, 16);
            font-weight: 300;

        }

        &-right {
            display: flex;
            align-items: center;

            @include fluid(gap, 12, 12, 12);
        }

        &-button {
            @include fluid(width, 35, 35, 35);
            @include fluid(height, 35, 35, 35);

            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: 0;
            padding: 0;

            &.muted {
                .wp-story-item__mute-wave {
                    opacity: 0;
                }
            }
        }
    }

    &__list {
        // position: absolute;
        // inset: 0;
        height: 100%;
    }

    &__progress {
        display: flex;
        align-items: center;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;

        @include fluid(padding-left, 15, 15, 15);
        @include fluid(padding-right, 15, 15, 15);
        @include fluid(gap, 5, 5, 5);
        @include fluid(margin-bottom, 20, 20, 20);
        @include fluid(padding-top, 17, 17, 17);

        &-item {
            flex: 1;
            background-color: #EDF0F3;
            overflow: hidden;
            position: relative;

            @include fluid(height, 5, 5, 5);

            &:first-child {
                @include fluid(border-top-left-radius, 10, 10, 10);
                @include fluid(border-bottom-left-radius, 10, 10, 10);
            }

            &:last-child {
                @include fluid(border-top-right-radius, 10, 10, 10);
                @include fluid(border-bottom-right-radius, 10, 10, 10);
            }

            &:not(.active) {
                div {
                    animation: none !important;
                }
            }

            &.showed {
                div {
                    width: 100% !important;
                    content:"";
                    display: block;
                    position: absolute;
                    inset: 0;
                    animation: none !important;
                    background-color: #0094FF;
                }
            }

            &.active {
                div {
                    content:"";
                    display: block;
                    position: absolute;
                    inset: 0;
                    animation: progress-bar-fill 5s linear forwards;
                    background-color: #0094FF;
                }
            }
        }
    }

    &:not(.wp-story-item--active) {
        .wp-story-item__progress-item.active div {
            animation: none !important;
            width: 0 !important;
        }
    }

    &__preview {
        position: absolute;
        cursor: pointer;
        z-index: 222;
        inset: 0;
        overflow: hidden;
        transition: opacity .65s ease-in-out, visibility .65s ease-in-out;
        background-color: #000;

        @include fluid(border-radius, 10, 10, 10);

        &-background {
            height: 100%;
            opacity: .6;

            img {
                object-fit: cover;
                height: 100%;
            }
        }

        img.wp-story-slide__bg {
            height: 100%;
            width: 100%;
            object-fit: cover;
            opacity: .7;
        }

        &-inner {
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            left:0;
            right:0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 2;
        }

        &-thumbnail {
            display: flex;
            justify-content: center;

            @include fluid(margin-bottom, 25, 25, 25);

            img {
                width: 50%;
                aspect-ratio: 1 / 1;
                object-fit: cover;
                border-radius: 100%;
                box-shadow: 0 0 20px #0000006e;
            }
        }

        &-title {
            @include fluid(font-size, 24, 24, 12);

            color: #fff;
            text-shadow: 0 5px 20px #0000006e;
            font-weight: 700;
            text-align: center;
        }

        &-date {
            @include fluid(font-size, 16, 16, 11);

            color: #fff;
            text-shadow: 0 5px 20px #0000006e;
            font-weight: 300;
            text-align: center;
        }
    }
}

@keyframes progress-bar-fill {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

.wp-stories__modal-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 100vw;

    &>.slick-list {
        @media(max-width: 768px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}

.wp-story-slide {
    margin: 0 !important;
    display: none;

    &.active {
        display: block;
    }

    img {
        height: 100%;
        object-fit: cover;
    }

    .wp-story-slide__bg, video {
        object-fit: cover;
        background: #fff !important;
        height: 100%;

        @media(width >= 768px) {
            width: 100%;
        }

        @media(width < 768px) {
            height: 100% !important;
            width: 100% !important;
            object-fit: cover !important;
        }
    }

    &__inner {
        position: absolute;
        top: 0;
        height: 100%;
        display: flex;
        flex-direction: column;

        @include fluid(padding-top, 150, 100, 50);
        @include fluid(padding-bottom, 50, 50, 50);
        @include fluid(padding-left, 30, 30, 30);
        @include fluid(padding-right, 30, 30, 30);


        @media(width < 768px) {
            padding-top: 31vw;
        }
    }

    &__thumbnail {
        @include fluid(max-width, 200, 200, 200);
        @include fluid(max-height, 200, 200, 200);

        width: 70%;
        aspect-ratio: 1 / 1;

        object-fit: cover;

        &--rounding-no_rounded {
            border-radius: 0;
        }

        &--rounding-20_rounded {
            @include fluid(border-radius, 20, 20, 20);
        }

        &--rounding-100_rounded {
            border-radius: 100%;
        }

        @media(width < 1024px) {
            width: 60% !important;
            aspect-ratio: 1 / 1;
            height: auto !important;
        }
    }
}

.wp-story-slider {
    height: 100%;

    .slick-track,
    .slick-list {
        height: 100%;
    }

    &__controls {
        &-btn {
            position: absolute;
            height: auto;
            width: 50%;
            z-index: 4;
            background: transparent;
            font-size: 0;
            opacity: 0;

            @include fluid(bottom, 100, 100, 100);
            @include fluid(top, 100, 100, 100);

            &--prev {
                left:0;
            }

            &--next {
                right: 0;
            }
        }
    }

    &__button {
        &-wrapper {
            position: absolute;
            left:0;
            z-index: 3;
            right:0;
            display: flex;
            justify-content: center;

            @include fluid(bottom, 34, 34, 34);
        }

        background-color: #0094FF;
        color: #fff;
        font-weight: 700;
        display: inline-flex;
        text-decoration: none;
        transition: background-color 0.3s;

        @include fluid(font-size, 18, 14, 18);
        @include fluid(padding-top, 15, 5, 15);
        @include fluid(padding-bottom, 15, 5, 15);
        @include fluid(padding-left, 40, 20, 40);
        @include fluid(padding-right, 40, 20, 40);
        @include fluid(border-radius, 100, 100, 100);

        &:hover {
            background-color: #0077CC;
        }
    }

    &>.slick-arrow {
        position: absolute;
        height: auto;
        width: 50%;
        z-index: 2;
        background: transparent;
        font-size: 0;
        opacity: 0;

        @include fluid(bottom, 100, 100, 100);
        @include fluid(top, 100, 100, 100);

        &.slick-next {
            right: 0;
        }
    }
}

.wp-story-slide {
    height: 100%;

    &::before {
        content: "";
        display: block;
        background: linear-gradient(180deg, #202020 0%, rgba(32, 32, 32, 0) 100%);
        opacity: 0.8;
        left: 0;
        right: 0;
        position: absolute;
        z-index: 1;
        top: 0;
        pointer-events: none;

        @include fluid(height, 194, 194, 194);
    }

    &::after {
        content: "";
        display: block;
        background: linear-gradient(0deg, #202020 0%, rgba(32, 32, 32, 0) 100%);
        opacity: 0.8;
        left: 0;
        right: 0;
        position: absolute;
        pointer-events: none;
        z-index: 1;
        bottom: 0;

        @include fluid(height, 253, 253, 253);
    }

    &__bg {
        aspect-ratio: .5624 / 1;
        object-fit: cover;
    }

    &__text {
        text-align: center;
        font-weight: bold;
        color: #fff;
        line-height: 1.35;
        text-shadow: 0 5px 20px #0000006e;
        position: relative;
        z-index: 2;

        @include fluid(font-size, 32, 48, 32);

        @media(width < 1024px) {
            font-size: 3.2vw;
        }

        @media(width < 768px) {
            font-size: 3vw;
        }
    }

    &--ai {
        background-color: #000;
    }
}

.wp-story-slide img.wp-story-slide__thumbnail {
    @include fluid(max-width, 200, 200, 200);
    @include fluid(max-height, 200, 200, 200);
    @include fluid(margin-bottom, 30, 30, 30);

    margin-left: auto;
    margin-right: auto;
}